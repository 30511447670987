import Swiper from 'swiper';

export default class {
    constructor({
        id,
        counterId,
        dragHandle,
        draggingClass,
        buttonHandle,
        count,
    }) {
        const el = document.getElementById(id);
        const swiperContainer = el.querySelector('.swiper-container');
        const counter = document.getElementById(counterId);
        const drag = el.querySelector(dragHandle);

		const breakpoints = len => {
			switch(len) {
				case 0:
				case 1: return {}
				case 2: return {
					768: {
						slidesPerView: 2,
						spaceBetween: 48,
					},
				};
				default: return {
					768: {
						slidesPerView: 2,
						spaceBetween: 24,
					},
					1500: {
						slidesPerView: 3,
						spaceBetween: 24,
					},
					1800: {
						slidesPerView: 4,
						spaceBetween: 24,
					},
				};
			}
		}

        const swiper = new Swiper(swiperContainer, {
            init: false,
            slidesPerView: 1,
            spaceBetween: 12,
			breakpoints: breakpoints(count),
        });

        function handleMouseEnter() {
            drag.style.opacity = 1;
        }
        function handleMouseLeave() {
            drag.style.opacity = 0;
        }
        function handleMouseMove({ clientX: x, clientY: y }) {
            const { top, left } = el.getBoundingClientRect();

            drag.style.transform = `translate(calc(${x - left}px - 50%), calc(${y - top}px - 50%))`;
        }

        function handleSlideChange({ realIndex, passedParams, slides }) {
            if (counter) {
                const offset = passedParams.loopedSlides * 2;

                counter.textContent = `0${realIndex + 1} // 0${slides.length - offset}`;
            }
        }
        function handleTouchStart() {
            el.classList.add(draggingClass);
        }
        function handleTouchEnd() {
            el.classList.remove(draggingClass);
        }
        function handleTouchMove(_, { clientX: x, clientY: y }) {
            const { top, left } = el.getBoundingClientRect();

            drag.style.transform = `translate(calc(${x - left}px - 50%), calc(${y - top}px - 50%))`;
        }

        function handleButtonEvents() {
            const buttons = el.querySelectorAll(buttonHandle);

            Array.from(buttons).forEach(button => {
                button.addEventListener('mouseenter', function() {
                    drag.style.display = 'none';
                });
                button.addEventListener('mouseleave', function() {
                    drag.style.display = 'block';
                });
            });
        }

        if (drag) {
            el.addEventListener('mouseenter', handleMouseEnter);
            el.addEventListener('mouseleave', handleMouseLeave);
            el.addEventListener('mousemove', handleMouseMove, { passive: true });

            swiper.on('slideChange', handleSlideChange);
            swiper.on('touchStart', handleTouchStart);
            swiper.on('touchEnd', handleTouchEnd);
            swiper.on('touchMove', handleTouchMove);
            swiper.on('init', handleButtonEvents);
        }

        // Initialize Swiper
        swiper.init();

        // Initialize counter
        if (counter) {
            const offset = swiper.passedParams.loopedSlides * 2;

            counter.textContent = `0${swiper.realIndex + 1} // 0${swiper.slides.length - offset}`;
        }
    }
}
