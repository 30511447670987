import Swiper from 'swiper';

export default class {
    constructor({
        id,
        controlsHandle,
        controlsPrevId,
        controlsNextId,
        counterId,
        count,
    }) {
        const el = document.getElementById(id);
        const controlsContainer = el.querySelector(controlsHandle);
        const controlsPrev = document.getElementById(controlsPrevId);
        const controlsNext = document.getElementById(controlsNextId);
        const swiperContainer = el.querySelector('.swiper-container');
        const counter = document.getElementById(counterId);

		const breakpoints = len => {
			switch(len) {
				case 0:
				case 1: return {}
				case 2: return {
					768: {
						slidesPerView: 2,
						spaceBetween: 48,
					},
				};
				default: return {
					768: {
						slidesPerView: 2,
						spaceBetween: 24,
					},
					1250: {
						slidesPerView: 3,
						spaceBetween: 24,
					},
					1800: {
						slidesPerView: 4,
						spaceBetween: 24,
					},
				};
			}
		}

        const swiper = new Swiper(swiperContainer, {
            init: false,
            slidesPerView: 1,
            spaceBetween: 12,
            navigation: {
                prevEl: controlsPrev,
                nextEl: controlsNext,
            },
			breakpoints: breakpoints(count),
            grabCursor: true,
        });

        function handleSlideChange({ realIndex, passedParams, slides }) {
            if (counter) {
                const offset = passedParams.loopedSlides * 2;

                counter.textContent = `0${realIndex + 1} // 0${slides.length - offset}`;
            }
        }

        swiper.on('breakpoint', function() {
            controlsContainer.style.display = count > swiper.params.slidesPerView ? 'block' : 'none';
        });
        swiper.on('slideChange', handleSlideChange);

        // Initialize Swiper
        swiper.init();

        // Initialize counter
        if (counter) {
            const offset = swiper.passedParams.loopedSlides * 2;

            counter.textContent = `0${swiper.realIndex + 1} // 0${swiper.slides.length - offset}`;
        }
    }
}
