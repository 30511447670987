export default class {
    constructor({
        id,
        activeClass,
        minusClass,
        plusButtons,
        bubbleButtons,
        contentBoxs,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);
        const buttons = el.querySelectorAll(plusButtons);
        const bubbles = el.querySelectorAll(bubbleButtons);
        const boxes = el.querySelectorAll(contentBoxs);
        const active = activeClass;
        const minus = minusClass;

        // Helper functions
        function resetAccordion() {
            Array.from(buttons).forEach((btn, i) => {
                btn.classList.remove(minus);
                boxes[i].classList.remove(active);
            });
        }
        function handleAccordion(box, btn) {
            const status = btn.classList.contains(minus);
            resetAccordion(btn);
            if (!status) {
                btn.classList.add(minus);
                box.classList.add(active);
            }
        }

        // Event handler functions
        const handleClicks = Array.from(buttons).map((btn, i) => () => {
            handleAccordion(boxes[i], btn);
        });

        // Add event listeners
        Array.from(buttons).forEach((btn, i) => {
            btn.addEventListener('click', handleClicks[i]);
        });
        Array.from(bubbles).forEach((btn, i) => {
            btn.addEventListener('click', handleClicks[i]);
        });
    }
}
