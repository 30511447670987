export default class {
    constructor({
        id,
        allHandle,
        noneHandle,
    }) {
        const el = document.getElementById(id);
        const allButtons = el.querySelectorAll(allHandle);
        const noneButtons = el.querySelectorAll(noneHandle);

        // Event handler functions
        function handleAll(e) {
            const button = e.currentTarget;
            const inputs = button.parentElement.nextElementSibling.querySelectorAll('input');

            Array.from(inputs).forEach(input => { input.checked = true; });
        }
        function handleNone(e) {
            const button = e.currentTarget;
            const inputs = button.parentElement.nextElementSibling.querySelectorAll('input');

            Array.from(inputs).forEach(input => { input.checked = false; });
        }

        // Add event listeners
        Array.from(allButtons).forEach(allButton => {
            allButton.addEventListener('click', handleAll);
        });
        Array.from(noneButtons).forEach(noneButton => {
            noneButton.addEventListener('click', handleNone);
        });
    }
}
