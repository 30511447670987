export default class {
    constructor({
        rangeBar,
        rangePrice,
    }) {
        // Elements and class variables        
        const rangeBarWidth = document.querySelector(rangeBar).offsetWidth;
        const rangePriceWidth = document.querySelector(rangePrice).offsetWidth;

        if (rangePriceWidth > rangeBarWidth) {
            const offSet = (rangePriceWidth - rangeBarWidth) / 2;
            const rangePriceContainer = document.querySelector(rangePrice);
            rangePriceContainer.style.left = `-${offSet}px`;
        }

        function handleFeatureButtonClick(event) {
            const target = (event.target.matches('[data-feature-index]') && event.target) || event.target.closest('[data-feature-index]')
            if (!target) {
                return;
            }

            // Wait for the content to animate in. Mostion is currently 500ms
            setTimeout(() => {
                window.location.hash = `feature-index-${target.dataset.featureIndex}`
            }, 600)
        }

        const featureButtons = Array.from(document.querySelectorAll('[data-feature-index]'))
        featureButtons.forEach((button) => {
            button.addEventListener('click', handleFeatureButtonClick)
        })
    }
}
