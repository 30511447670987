export default class {
    constructor({
        id,
        installerHandle,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);
        const search = el.querySelector('[type="search"]');
        const installers = el.querySelectorAll(installerHandle);

        // Event handler functions
        function handleKeyup(e) {
            // Clear search value for escape key
            if (e.keyCode === 27) search.value = '';

            Array.from(installers).forEach(installer => {
                const installerText = installer.textContent.toLowerCase();
                const searchText = search.value.toLowerCase();

                installer.style.display = installerText.includes(searchText) ? 'block' : 'none';
            });
        }
        function handleFocus() {
            search.addEventListener('keyup', handleKeyup);
        }
        function handleBlur() {
            search.removeEventListener('keyup', handleKeyup);
        }

        // Add event listeners
        search.addEventListener('focus', handleFocus);
        search.addEventListener('blur', handleBlur);

        // Initialize by focusing search input
        search.focus();
    }
}
