// Imports
import SwiperCore, { Autoplay } from 'swiper/core';
SwiperCore.use([Autoplay]);

// Global variables

// Class
export default class {
    constructor({
        id,
        counterHandle,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);
        const container = el.querySelector('.swiper-container');
        const buttons = Array.from(el.querySelectorAll(counterHandle));

        const swiper = new SwiperCore(container, {
            init: false,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
        });

        // State variables

        // Helper functions

        // Event handler functions

        // Attach event listeners
        swiper.on('activeIndexChange', function() {
            buttons.forEach((button, i) => {
                if (swiper.activeIndex === i) {
                    button.classList.add('is-active');
                } else {
                    button.classList.remove('is-active');
                }
            });
        });

        swiper.on('init', function() {
            buttons[0].classList.add('is-active');
        });

        buttons.forEach((button, i) => {
            button.addEventListener('click', function() {
                swiper.slideTo(i);
            });
        });

        // Initialize
        swiper.init();
    }
}
