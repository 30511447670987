import { router } from 'ui-utilities';

export default class {
    constructor({
        id,
        type,
        name,
        conditionalName,
        conditionalValue,
        conditionalOptionsUrl = '',
        overlayLabelsClass,
        focusClass,
        actions,
        events,
    }) {
        const el = document.getElementById(id);
        const form = el.closest('form');

        const input = type === 'checkbox'
            ? el.querySelector(`[name="${name}[]"]`)
            : el.querySelector(`[name="${name}"]`);

        const targets = conditionalName
            ? form.querySelectorAll(`[name="${conditionalName}"]`)
            : [];

        function toggleVisibility(visible) {
            el.style.display = visible ? 'block' : 'none';
        }

        function handleSetInputValue(e) {
            const { name: n, value } = e.detail;

            if (n === name) {
                input.value = value;
            }
        }

        function testConditional(val, match) {
            switch (typeof match) {
                case 'string': return val === match;
                case 'object': return match.includes(val);
				default: return false;
            }
        }

        function handleTargetChange(e) {
            const { value } = e.currentTarget;
            const show = testConditional(value, conditionalValue);

            // Check if we need to fetch dynamic options before showing input
            if (conditionalOptionsUrl === '') {
                toggleVisibility(show);

                return;
            }

            // No need to request dynamic options if input is hidden
            if (!show) {
                toggleVisibility(show);

                return;
            }

            // Get dynamic options
            const url = conditionalOptionsUrl;
            const query = { value };

            function cb(res) {
                const { options } = JSON.parse(res);

                // Populate county select w/ options
                if (options.length) {
                    input.innerHTML = options
                        .map(({ optValue, optLabel }) => `<option value="${optValue}">${optLabel}</option>`)
                        .join('');
                }

                toggleVisibility(show);
            }

            router.get({ url, query, cb });
        }

        function handleFocus() {
            if (el.classList.contains(overlayLabelsClass)) {
                el.classList.add(focusClass);
            }
        }
        function handleBlur() {
            if (el.classList.contains(overlayLabelsClass)) {
                el.classList.toggle(focusClass, input.value);
            }
        }
        function handleChange() {
            const tags = ['INPUT'];
            const types = ['text', 'email', 'password'];

            if (tags.includes(input.tagName) && types.includes(input.getAttribute('type'))) {
                if (input.value !== '') {
                    el.classList.add('has-value');
                } else {
                    el.classList.remove('has-value');
                }
            }
        }

        // Attach event handlers
        events.on(actions.setInputValue, handleSetInputValue);
        targets.forEach(target => {
            target.addEventListener('change', handleTargetChange);
        });
        input.addEventListener('focus', handleFocus);
        input.addEventListener('blur', handleBlur);
        input.addEventListener('change', handleChange);

        // Initalize
        handleChange();

        if (conditionalName) {
            const formData = new FormData(form);

            toggleVisibility(testConditional(formData.get(conditionalName), conditionalValue));
        }
    }
}
