import Swiper from 'swiper';

export default class {
    constructor({
        id,
        prevId,
        nextId,
    }) {
        const el = document.getElementById(id);
        const swiperContainer = el.querySelector('.swiper-container');
        const controlsPrev = document.getElementById(prevId);
        const controlsNext = document.getElementById(nextId);

        const swiper = new Swiper(swiperContainer, {
            init: false,
            slidesPerView: 2,
            spaceBetween: 30,
            navigation: {
                prevEl: controlsPrev,
                nextEl: controlsNext,
            },
            breakpoints: {
                768: {
                    slidesPerView: 3,
                },
            },
        });

        // Initialize Swiper
        swiper.init();
    }
}
