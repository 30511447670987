/**
 * API documention for Vimeo player:
 * https://github.com/vimeo/player.js
 */

import Player from '@vimeo/player';

export default class {
    constructor({
        id,
        pausedClass,
        actions,
        events,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);
        const iframe = el.querySelector('iframe');
        const player = new Player(iframe);

        // Helper functions
        function playVideo() {
            player.play();

            el.classList.remove(pausedClass);
        }
        function pauseVideo() {
            player.pause();

            el.classList.add(pausedClass);
        }

        // Event handler functions
        function handleCloseModal(e) {
            e.preventDefault();

            pauseVideo();
        }

        // Add event listeners
        events.on(actions.closeModal, handleCloseModal);

        // Initialize
        playVideo();
    }
}
