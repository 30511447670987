import SwiperCore, { Navigation } from 'swiper/core';

SwiperCore.use([Navigation]);
export default class {
    constructor({
        id,
    }) {
        const el = document.getElementById(id);
        const swiperContainer = el.querySelector('.swiper-container');

        const swiper = new SwiperCore(swiperContainer, {
            slidesPerView: 1,
            spaceBetween: 12,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                768: {
                    spaceBetween: 60,
                    slidesPerView: 3,
                },
                1250: {
                    spaceBetween: 90,
                    slidesPerView: 3,
                },
                1500: {
                    spaceBetween: 110,
                    slidesPerView: 3,
                },
            },
        });

        // Initialize
        swiper.init();
    }
}
