import { router, throttle } from 'ui-utilities';

export default class {
    constructor({
        id,
        reviewHandle,
        moreHandle,
        activeClass,
        reviewPageId,
        initialReviewLimit,
        actions,
        events,
    }) {
        const el = document.getElementById(id);
        const review = el.querySelector(reviewHandle);
        const list = el.querySelector('ul');
        const more = el.querySelector(moreHandle);

        function setListHeight() {
            // Set height to first 5 reviews
            const height = Array.from(list.children)
                .slice(0, initialReviewLimit)
                .reduce((acc, curr) => curr.offsetHeight + acc + 24, 0); // add margin

            list.style.maxHeight = `${height}px`;
        }
        function collapse() {
            el.classList.remove(activeClass);
            setListHeight();
        }

        // Event handler functions
        const handleResize = throttle(() => {
            if (el.classList.contains(activeClass)) {
                return;
            }

            setListHeight();
        }, 500);
        function handleReview(e) {
            e.preventDefault();

            const url = '/shared/submit-review';
            const query = { reviewPageId };

            function cb(res) {
                const markup = res;
                const bg = 'md';

                events.emit(actions.loadModal, { markup, bg });
            }

            router.get({ url, query, cb });
        }
        function handleMore() {
            if (el.classList.toggle(activeClass)) {
                list.style.maxHeight = 'inherit';
            } else {
                collapse();
            }
        }

        // Enable events
        window.addEventListener('resize', handleResize);
        if (review) review.addEventListener('click', handleReview);
        if (more) more.addEventListener('click', handleMore);

        // Initialize
        collapse();
    }
}
