export default class {
    constructor({
        id,
        projectCount,
        projectLimit,
        matrixHandle,
        blockHandle,
        addHandle,
        removeHandle,
        appendHandle,
        descInstructions,
        descCharLimit,
        imgInstructions,
    }) {
        const el = document.getElementById(id);
        const addButton = el.querySelector(addHandle);
        const removeButtons = el.querySelectorAll(removeHandle);
        const container = el.querySelector(appendHandle);
        const count = projectCount;
        const limit = projectLimit;

        let i = 0;

        function getTemplate() {
            i++;

            return `
                <legend>Project ${count + i}</legend>
                <input type="hidden" name="fields[${matrixHandle}][blocks][new:${i}][type]" value="${blockHandle}" />
                <input type="hidden" name="fields[${matrixHandle}][blocks][new:${i}][enabled]" value="1" />
                <input type="hidden" name="fields[${matrixHandle}][sortOrder][]" value="new:${i}" />

                <div class="input input--text">
                    <label
                        for="fields-${matrixHandle}-blocks-new:${i}-fields-projectDescription"
                        class="input__label">Project Description</label>
                    <input
                        type="text"
                        id="fields-${matrixHandle}-blocks-new:${i}-fields-projectDescription"
                        class="input__input input__input--text"
                        name="fields[${matrixHandle}][blocks][new:${i}][fields][projectDescription]" />

                    <p><small>${descInstructions}</small></p>
                    <p><small>Character Limit: ${descCharLimit}</small></p>
                </div>

                <div class="input input--file">
                    <label
                        for="fields-${matrixHandle}-blocks-new:${i}-fields-projectImage"
                        class="input__label">Project Image</label>
                    <input
                        id="fields-${matrixHandle}-blocks-new:${i}-fields-projectImage"
                        type="file"
                        class="input__input input__input--file input--required"
                        name="fields[${matrixHandle}][blocks][new:${i}][fields][projectImage][]"
                        value="" />

                    <p><small>${imgInstructions}</small></p>
                </div>
            `;
        }

        // Event handler functions
        function handleAdd(e) {
            e.preventDefault();

            let field = document.createElement('fieldset');
            field.classList.add('injected');
            field.innerHTML = getTemplate();
            container.append(field);

            if (count + i >= limit) {
                addButton.style.display = 'none';
            }
        }

        function handleRemove(e) {
            e.preventDefault();

            this.closest('fieldset').remove();
        }

        // Add event listeners
        addButton.addEventListener('click', handleAdd);

        Array.from(removeButtons).forEach(btn => {
            btn.addEventListener('click', handleRemove);
        });
    }
}
