// Imports
import { router } from 'ui-utilities';

// Global variables

// Class
export default class {
    constructor({
        id,
        actions,
        events,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);
        const links = el.querySelectorAll('a');

        // State variables

        // Helper functions

        // Event handler functions
        const handleClick = Array.from(links).map(anchor => e => {
            e.preventDefault();

            const url = anchor.getAttribute('href');

            function cb(res) {
                const markup = res;

                events.emit(actions.loadModal, { markup, bg: 'white' });
            }

            router.get({ url, cb });
        });

        // Attach event listeners
        Array.from(links).forEach((anchor, i) => {
            anchor.addEventListener('click', handleClick[i])
        });

        // Initialize
    }
}
