import { load } from 'recaptcha-v3';

const RECAPTCHA_SITE_KEY = '6Le0NvElAAAAAE-KnBwe6H8pSUTflWsC1n7jKM8v';

export default class {
    constructor({
        id,
    }) {
        const el = document.getElementById(id);
        const form = el.querySelector('form');

        if (!form) {
            return;
        }

        const submit = form.querySelector('[type="submit"]');

        let recaptcha = null;

        function handleSubmit(e) {
            if (recaptcha === null) {
                return;
            }

            e.preventDefault();

            submit.disabled = true;
            submit.value = 'Processing...';

            // Get reCAPTCHA score token
            recaptcha.execute('form').then(token => {
                // Create hidden input in form to pass reCAPTCHA score token to server
                const input = document.createElement('input');
                input.setAttribute('type', 'hidden');
                input.setAttribute('name', 'token');
                input.setAttribute('value', token);

                form.appendChild(input);
                form.submit();
            });
        }

        // Add event handlers
        // Load reCAPTCHA object from key
        load(RECAPTCHA_SITE_KEY).then(r => {
            recaptcha = r;

            form.addEventListener('submit', handleSubmit);
        });
    }
}
